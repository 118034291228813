import {
  ButtonInner,
  getInnerButtonParentClasses,
} from '../ButtonInner/ButtonInner';

import type { CSSProperties } from 'react';
import type { ButtonInnerProps } from '../ButtonInner/ButtonInner';

export type ButtonProps = ButtonInnerProps &
  JSX.IntrinsicElements['button'] & {
    innerButtonClassName?: string;
    innerButtonStyle?: CSSProperties;
  };

// This is a button that can be used as a regular button, <button> element
export const Button = ({
  children,
  innerButtonClassName,
  innerButtonStyle,
  className,
  color,
  hasShadow,
  icon,
  isFullWidth,
  hasProgressState = false,
  isOutline,
  isRounded,
  size,
  ...buttonProps
}: ButtonProps) => {
  return (
    <button
      {...buttonProps}
      data-testid="aslan-button"
      className={getInnerButtonParentClasses(
        `${className || ''} ${isFullWidth ? 'w-full' : ''}`
      )}
    >
      <ButtonInner
        className={innerButtonClassName}
        hasProgressState={hasProgressState}
        color={color}
        hasShadow={hasShadow}
        icon={icon}
        isFullWidth={isFullWidth}
        isOutline={isOutline}
        isRounded={isRounded}
        size={size}
        style={innerButtonStyle}
      >
        {children}
      </ButtonInner>
    </button>
  );
};

export default Button;
